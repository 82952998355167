import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CustomCarousel = ({images}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="w-full h-[600px] mb-36 rounded-3xl relative">
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-[600px] transition-all ease-in-out duration-500">
        <img
          src={`${process.env.REACT_APP_BASE_URL}${images[activeIndex]}`}
          className="w-full h-full object-cover object-center"
          alt={images[activeIndex]}
        />
      </div>

      {/* Overlay to darken background for text visibility */}
      <div className="absolute inset-0 bg-black bg-opacity-0"></div>

      {/* Swiper */}
      <div className="relative z-10 h-full">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".custom-next", // Custom next button class
            prevEl: ".custom-prev", // Custom previous button class
          }}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          className="h-full"
        >
          {images &&
            images.map((item, index) => (
              <SwiperSlide key={index}>
                <div></div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      {/* Navigation buttons */}
      <div className="absolute inset-y-0 left-0 flex items-center justify-center z-20">
        <button
          className="custom-prev p-6 text-sm bg-gray-400 text-white rounded-full shadow-lg"
        >
          &#10094; {/* Custom Previous Icon */}
        </button>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center justify-center z-20">
        <button
          className="custom-next p-6 text-sm bg-gray-400 text-white rounded-full shadow-lg"
        >
          &#10095; {/* Custom Next Icon */}
        </button>
      </div>
      {/* Thumbnail List */}
      <div className=" bottom-0 left-0 w-full z-10 p-4 flex justify-center space-x-4 ">
        {images && images.map((item, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_BASE_URL}${item}`} 
            alt={item}
            className={`w-16 h-16 sm:w-24 sm:h-24 object-cover cursor-pointer transition-transform duration-300 rounded ${
              activeIndex === index
                ? "transform scale-110 border-2 border-black"
                : ""
            }`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
