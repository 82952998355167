const PaymentPlan = ({plan, masterplan}) => {

  const payments = [
    { percentage: `${plan.downPayment}%`, description: "Down Payment" },
    { percentage: `${plan.duringConstruction}%`, description: "During Construction" },
    { percentage: `${plan.onHandover}%`, description: "On Handover" },
  ]

  return (
    <div className="w-full flex flex-col">
      <div className="py-8">
        <h2 className="text-3xl font-semibold mb-6 text-start">PAYMENT PLAN</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 px-4 lg:px-0">
          {payments.map((payment, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center border border-gray-200 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <h3 className="text-green-500 text-3xl font-semibold mb-2">
                {payment.percentage}
              </h3>
              <p className="text-gray-600 text-sm text-center">
                {payment.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      {masterplan.length > 0 && <div className="flex flex-col mt-10 gap-2 w-full  ">
        <h1 className="text-start text-3xl font-semibold">MASTER PLAN</h1>
        <img
          className="mx-auto w-full  h-auto" 
          src={`${process.env.REACT_APP_BASE_URL}${masterplan}`}
          alt="MasterPlan"
        />
      </div>}
    </div>
  );
};

export default PaymentPlan;
