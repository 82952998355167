import React, { useEffect, useState } from "react"; 
import { Link } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import axios from "axios"; 

const ProjectCardsList = () => {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.asquared.ae/api/offplanlistings/?domain=dubaihillestate.com");
        setListings(response.data.offPlanListings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {listings.length > 0 && listings.map((item, index) => ( 
        <Link key={item.id} to={"/more-projects"}>
          <ProjectCard info={item} index={index} /> 
        </Link>
      ))}
    </div>
  );
};

export default ProjectCardsList;
