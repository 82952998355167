import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BackGroundImage from "../components/BackGroundImage";
import ImageSlider from "../components/OffPlanCrousal";
import AnotherSection from "../components/AnotherSection";
import PaymentPlan from "../components/PaymentPlan";
import FloorPlans from "../components/Floors";

const OffPlanPropertyPage = () => {
  const { slug } = useParams(); 
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.asquared.ae/api/offplanlistings/slug/${slug}`);
        setData(response.data); 
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="flex flex-col">
      <BackGroundImage text={data.title} /> 
      <div className="w-[80%] flex flex-row justify-between gap-3 my-10 mx-auto">
        <div className="w-full h-auto lg:min-w-[80%] rounded-3xl">
          <ImageSlider images={data.displayImages} />
          <AnotherSection info={data} /> 
          <FloorPlans units={data.units} />
          <PaymentPlan plan={data.paymentPlan} masterplan={data.masterplan}/> 
        </div>
        <div className="hidden h-auto lg:inline min-w-[20%] space-y-3">
          <h1 className="text-xl font-semibold">Specifications</h1>
          <ul className="font-light space-y-2">
          {data.beds > 0 && <li>{data.beds} Beds</li>}
          {data.baths > 0 && <li>{data.baths} Baths</li>}
          {data.area > 0 && <li>{data.area} sqft</li>}
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OffPlanPropertyPage;
