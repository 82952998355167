import { Button } from "flowbite-react";
import React, { useState } from "react";
import PopUp from "./PopUp";
import style from "./styleSection.css"

const AnotherSection = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleContactClick = (e) => {
    setIsOpen(() => !isOpen); // Toggle popup
  };

  // Split the amenities string by either comma or "•" and filter out empty entries
  const amenitiesList = info.amenities[0]
    ? info.amenities[0].split(/,|•/).map((item) => item.trim()).filter(Boolean)
    : [];

  return (
    <div>
      <div className="w-screen">
        {isOpen && <PopUp />}
      </div>
      <div className="my-5 mx-auto">
        <div className="flex justify-end mb-6">
          <Button onClick={handleContactClick} gradientMonochrome="success">
            DOWNLOAD BROCHURE
          </Button>
        </div>
        <div className="flex gap-6 flex-col my-6">
          <p
            className="px-6 lg:px-0 font-extralight"
            dangerouslySetInnerHTML={{ __html: info.description }}
          ></p>
        </div>
        <div className="flex flex-col gap-3">
        {amenitiesList.length > 0 && (
          <>
            <h1 className="text-3xl font-semibold">Amenities</h1>
            <ul className="grid sm:grid-cols-2 grid-flow-row place-content-start space-y-2">
              {amenitiesList.map((amenity, index) => (
                <li key={index} className="flex gap-2 items-center">
                  <p className="bg-green1 h-2 w-2 rounded-full"></p>
                  {amenity}
                </li>
              ))}
            </ul>
          </>
        )}

     </div>
      </div>
    </div>
  );
};

export default AnotherSection;
