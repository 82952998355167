import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Button, Card } from "flowbite-react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CustomCarousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const listings = data ? data.offPlanListings : [];

  if (!listings || listings.length === 0) {
    return <div>No listings available</div>;
  }

  function formatPrice(price) {

    if (price >= 1000000) {
      return (price / 1000000).toFixed(1) + "M";
    } else if (price >= 1000) {
      return (price / 1000).toFixed(0) + "K";
    } else {
      return price.toString();
    }
  }
  

  return (
    <div className="relative w-full h-60 sm:h-screen">
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-full transition-all ease-in-out duration-500">
        <img
          // src={listings[activeIndex].bgImage} {/* Converted from bgImage */}
  src={`https://api.asquared.ae${listings[activeIndex].displayImages[0]}`}

          className="w-full h-full object-cover object-center"
          // alt={listings[activeIndex].title} {/* Converted from title */}
    alt={listings[activeIndex].title}

        />
      </div>

      {/* Swiper */}
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={{
          navigation : true ,
          nextEl: ".custom-next", // Custom next button class
          prevEl: ".custom-prev", // Custom previous button class
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="relative h-full z-10"
      >
        {listings.map((item) => (
          <SwiperSlide key={item.id}>
            {/* Front Card */}
            <div className="absolute inset-0 hidden lg:flex justify-center items-center sm:mr-[40%] sm:mt-[40%] md:mr-[55%] md:mt-[10%]">
              <Card
                className="bg-white w-[600px] h-[400px] bg-opacity-70 p-6 space-x-6 text-black font-semibold rounded-lg shadow-lg max-w-md"
                renderImage={() => (
                  <img
                    width={250}
                    height={250}
                    src={`https://api.asquared.ae${item.displayImages[0]}`} 
                    alt={item.title}
                    className="rounded-lg object-cover"
                  />
                )}
                horizontal
              >
                <h5 className="text-xl font-bold mb-4">{item.title}</h5> {/* Converted from title */}
                {item.location && <p className="text-lg">{item.location}</p> }
               {item.developer && <p className="text-lg">By {item.developer.title}</p> }
               {item.price && <p className="text-lg">Starts at {formatPrice(item.price) } AED</p> }
                <Link to={`/offproperty/${item.slug}`}> {/* Converted from slug */}
                  <Button gradientMonochrome="success">More Info</Button>
                </Link>
              </Card>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute inset-y-0 left-0 flex items-center justify-center z-20">
        <button onClick={navigator.prevEl} className="custom-prev p-6 text-sm bg-gray-400 text-white rounded-full shadow-lg">
          &#10094; {/* Custom Previous Icon */}
        </button>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center justify-center z-20">
        <button onClick={navigator.nextEl} className="custom-next p-6 text-sm bg-gray-400 text-white rounded-full shadow-lg">
          &#10095; {/* Custom Next Icon */}
        </button>
      </div>
    </div>
  );
};

export default CustomCarousel;
