import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomCarousel from "../components/Crousal";
import DubaiHillsInfo from "../components/DubaiHillsInfo";
import PropertyCards from "../components/PropertyCards";
import Features from "../components/Features";

const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.asquared.ae/api/offplanlistings/?domain=dubaihillestate.com&limit=15");
        setData(response.data); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="overflow-x-hidden">
        <CustomCarousel data={data} />
      </div>
      <div>
        <DubaiHillsInfo />
      </div>
      <div>
        <PropertyCards data={data}/>
      </div>
      <div>
        <Features />
      </div>
    </>
  );
};

export default Home;
