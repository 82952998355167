import React, { useState, useEffect } from "react";

const FloorPlans = ({ units }) => {
  // Get the first category with status true or empty string
  const getDefaultCategory = () => {
    const defaultCategory = Object.keys(units).find((key) => units[key].status === true);
    return defaultCategory || null; // Return the first category with status true, or null if none found
  };

  const [selectedCategory, setSelectedCategory] = useState(getDefaultCategory);

  useEffect(() => {
    setSelectedCategory(getDefaultCategory);
  }, [units]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // If no category with status true, return empty div
  if (!selectedCategory) {
    return <div />;
  }

  // Generate buttons for each category
  const unitCategories = Object.keys(units)
  .filter((unitType) => units[unitType].status === true)
  .map((unitType) => (
    <button
      key={unitType}
      className={`px-4 py-2 mx-2 rounded ${
        selectedCategory === unitType ? "bg-green-500 text-white" : "bg-gray-200 text-gray-700"
      } transition-all duration-300`}
      onClick={() => handleCategoryChange(unitType)}
    >
      {unitType.charAt(0).toUpperCase() + unitType.slice(1)} {/* Capitalize first letter */}
    </button>
  ));

  // Extract floor images for the selected category
  const selectedUnitImages = units[selectedCategory][`${selectedCategory}FloorImages`] || [];

  return (
    <div className="container mx-auto py-8">
      {/* Heading */}
      <h2 className="text-3xl font-semibold mb-4 text-start">FLOOR PLANS</h2>
      {/* Category Tabs */}
      <div className="flex md:flex-row flex-col gap-2 md:gap-0 justify-left mb-8">
        {unitCategories}
      </div>

      {/* Floor Plans Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {selectedUnitImages.length > 0 ? (
          selectedUnitImages.map((image, index) => (
            <div
              key={index}
              className="flex flex-col items-center bg-white shadow-lg p-4 rounded-md transition-transform duration-300 hover:scale-105"
            >
              <img
                src={process.env.REACT_APP_BASE_URL + image}
                alt={`${selectedCategory} floor ${index + 1}`}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <h3 className="text-lg font-semibold">
                {selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Floor Plan {index + 1}
              </h3>
            </div>
          ))
        ) : (
          <p>No floor plans available for this category.</p>
        )}
      </div>
    </div>
  );
};

export default FloorPlans;
